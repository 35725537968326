// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lds-ripple {
  display: inline-block;
  position: relative;
}
.lds-ripple div {
  position: absolute;
  border: 2px solid #000;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 45%;
    left: 45%;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 90%;
    height: 90%;
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/spinner/spinner.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,UAAU;EACV,kBAAkB;EAClB,8DAA8D;AAChE;AACA;EACE,sBAAsB;AACxB;AACA;EACE;IACE,QAAQ;IACR,SAAS;IACT,QAAQ;IACR,SAAS;IACT,UAAU;EACZ;EACA;IACE,QAAQ;IACR,SAAS;IACT,QAAQ;IACR,SAAS;IACT,UAAU;EACZ;EACA;IACE,QAAQ;IACR,SAAS;IACT,QAAQ;IACR,SAAS;IACT,UAAU;EACZ;EACA;IACE,QAAQ;IACR,SAAS;IACT,UAAU;IACV,WAAW;IACX,UAAU;EACZ;AACF","sourcesContent":[".lds-ripple {\n  display: inline-block;\n  position: relative;\n}\n.lds-ripple div {\n  position: absolute;\n  border: 2px solid #000;\n  opacity: 1;\n  border-radius: 50%;\n  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;\n}\n.lds-ripple div:nth-child(2) {\n  animation-delay: -0.5s;\n}\n@keyframes lds-ripple {\n  0% {\n    top: 45%;\n    left: 45%;\n    width: 0;\n    height: 0;\n    opacity: 0;\n  }\n  4.9% {\n    top: 45%;\n    left: 45%;\n    width: 0;\n    height: 0;\n    opacity: 0;\n  }\n  5% {\n    top: 45%;\n    left: 45%;\n    width: 0;\n    height: 0;\n    opacity: 1;\n  }\n  100% {\n    top: 0px;\n    left: 0px;\n    width: 90%;\n    height: 90%;\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
